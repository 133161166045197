.container {
  padding: 129px;
}

[data-theme="legrandtheatre"] .container {
  padding: 0;
}

[data-theme="legrandtheatre"] .container {
  padding: 0;
}

.header {
  font-family: var(--header-font-family);
  text-transform: uppercase;
}

.fr .header {
  font-size: 60px;
  line-height: 86px;
  letter-spacing: 2.62px;
}

[data-theme="legrandtheatre"] .fr .header {
  padding: 0 165px;
  font-size: 110px;
  line-height: 100px;
}

[data-theme="legrandtheatre"] .fr .not-found,
[data-theme="legrandtheatre"] .fr .or,
[data-theme="legrandtheatre"] .fr .bad-reading {
  transform: scaleX(0.825);
  text-transform: none;
  font-size: 44px;
  padding: 0 2px;
}

[data-theme="legrandtheatre"] .fr .header {
  padding: 0 165px;
  font-size: 110px;
  line-height: 100px;
}

[data-theme="legrandtheatre"] .fr .not-found,
[data-theme="legrandtheatre"] .fr .or,
[data-theme="legrandtheatre"] .fr .bad-reading {
  transform: scaleX(0.8);
  text-transform: uppercase;
  font-size: 46px;
}

.en {
  font-size: 36px;
  letter-spacing: 1.58px;
}

.en .header {
  font-size: 48px;
  line-height: 70px;
  letter-spacing: 2.1px;
}

.not-found {
  --target-margin-top: 50px;
  margin-top: var(--target-margin-top);
}

[data-theme="legrandtheatre"] .not-found {
  --target-margin-top: 70px;
}

.or {
  margin:
    55px
    0;
}

.separator {
  border: none;
  width: 100%;
  height: 12px;
  background-color: #FFFFFF;
  margin:
    96px
    auto;
}
