.thumbs-up {
  height: 172px;
  width: 152px;
}

[data-theme="legrandtheatre"] .thumbs-up {
  height: 186px;
  width: 193px;
}

[data-theme="legrandtheatre"] .thumbs-up {
  height: 186px;
  width: 193px;
}

.open-gift {
  height: 152px;
  width: 152px;
}

[data-theme="legrandtheatre"] .open-gift {
  height: 265px;
  width: 236px;
  margin-left: -30px;
}

[data-theme="legrandtheatre"] .open-gift {
  height: 265px;
  width: 236px;
  margin-left: -30px;
}

.closed-gift {
  height: 158.34px;
  width: 152px;
}

[data-theme="legrandtheatre"] .closed-gift {
  height: 185px;
  width: 185px;
}

[data-theme="legrandtheatre"] .closed-gift {
  height: 185px;
  width: 185px;
}
