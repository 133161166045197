@import url("./assets/fonts/GoboldLowplusFont.css");
@import url("./assets/fonts/GTQ-Neue-Plak-Primo-Compressed-Bold.css");

:root {
  --header-font-family: 'Gobold Lowplus Regular';
  --text-font-family: 'Helvetica Regular', Arial, sans-serif;
  --theme-accent-background-color: #7328FB;
  --theme-accent-alt-color: #7328FB;
}

[data-theme="mtelus"] {
  --header-font-family: 'Gobold Lowplus Regular';
  --text-font-family: 'Helvetica Regular', Arial, sans-serif;
  --theme-accent-background-color: #7328FB;
  --theme-accent-alt-color: #7328FB;
}

[data-theme="legrandtheatre"] {
  --header-font-family: 'GTQ Neue Plak Primo Compressed Bold';
  --text-font-family: 'Helvetica Regular', Arial, sans-serif;
  --theme-accent-background-color: #6600D7;
  --theme-accent-alt-color: #7C64FE;
}

body {
  margin: 0;
  height: var(--height);
  width: var(--width);
  color: #FFFFFF;
  background-color: #000000;
  text-align: center;
  overflow: hidden;

  font-family: var(--text-font-family);
  font-size: 42px;
  letter-spacing: 1.84px;
}

p {
  margin: 0;
}

h1 {
  margin: 0;
}

img {
  color: rgba(0, 0, 0, 0) !important;
}

* {
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}
