.content {
  display: grid;
  grid-template-columns: auto 68% auto;
  
  padding:
    52px
    0
    48px
    0;
}

.decorative-arrow {
  height: 100%;
}

.prompt {
  font-family: var(--header-font-family);
  text-transform: uppercase;
}

.prompt.fr {
  margin-top: 39px;
  margin-bottom: 29px;

  font-size: 54px;
  line-height: 76px;
  letter-spacing: 9.64px;
}

.prompt.en {
  margin-top: 29px;
  margin-bottom: 22px;

  font-size: 44px;
  line-height: 64px;
  letter-spacing: 12.76px;

  padding:
    0
    30px;
}

.separator {
  width: 100%;
  height: 4px;
  margin: 0 auto;
  border: none;
  background-color: #FFFFFF;
}

.separator.thick {
  height: 12px;
}
