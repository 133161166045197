.container {
  padding:
    0
    125px;
}

[data-theme="legrandtheatre"] .container {
  padding: 0;
}

[data-theme="legrandtheatre"] .container {
  padding: 0;
}

.header {
  --target-font-size: 80px;
  --target-line-height: 116px;

  font-family: var(--header-font-family);
  font-size: var(--target-font-size);
  line-height: var(--target-line-height);
  letter-spacing: 3.5px;
  text-transform: uppercase;
}

[data-theme="legrandtheatre"] .header {
  --target-font-size: 156px;
  --target-line-height: 140px;
  margin-top: 85px;
  padding:
    0
    127px;

  letter-spacing: initial;
}

.message {
  margin-top: 84px;
}

[data-theme="legrandtheatre"] .message {
  transform: scaleX(0.75) scaleY(0.95);
  text-transform: none;
  font-size: 46px;
  margin-top: 31px;
}

[data-theme="legrandtheatre"] .message {
  transform: scaleX(0.75) scaleY(0.95);
  text-transform: uppercase;
  font-size: 46px;
  margin-top: 31px;
}
