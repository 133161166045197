.container {
  --target-padding: 140px;
  height: 100%;
  padding-left: var(--target-padding);
  padding-right: var(--target-padding);
}

[data-theme="legrandtheatre"] .container {
  --target-padding: 92px;
}

.prompt {
  --target-margin-top: 345px;
  font-family: var(--header-font-family);
  text-transform: uppercase;
  font-size: 113px;
  letter-spacing: -5px;
  margin-top: var(--target-margin-top);
}

[data-theme="legrandtheatre"] .prompt {
  --target-margin-top: 311px;
  letter-spacing: initial;
  font-size: 190px;
  line-height: 166px;
}

.venues-container {
  --target-height: 870px;
  --target-margin-top: 60px;

  height: var(--target-height);
  margin-top: var(--target-margin-top);
  padding: 0 10px;
  display: flex;
  flex-direction: column;
  row-gap: 60px;

  scrollbar-width: 5px;
  scrollbar-color: var(--theme-accent-alt-color);
}

[data-theme="legrandtheatre"] .venues-container {
  --target-height: 825px;
  --target-margin-top: 40px;
}

.venues-container::-webkit-scrollbar {
  width: 5px;
}

.venues-container::-webkit-scrollbar-track {
  background: transparent;
}

.venues-container::-webkit-scrollbar-thumb {
  border: 1px solid var(--theme-accent-alt-color);
  background: var(--theme-accent-alt-color);
  margin-left: 10px;
}

.venue-button {
  --border-size: 5px;

  width: 100%;
  min-height: 380px;
  background: none;
  border: var(--border-size) solid var(--theme-accent-alt-color);

  padding:
    35px
    30px;

  font-family: var(--header-font-family);
  text-transform: uppercase;
  color: white;
  font-size: 79px;
  letter-spacing: -5px;
}

[data-theme="legrandtheatre"] .venue-button {
  --border-size: 10px;

  font-size: 119px;
  line-height: 101px;
  letter-spacing: initial;
}

.venue-button-content {
  margin-bottom: -15px;
}

[data-theme="legrandtheatre"] .venue-button-content {
  margin-bottom: -25px;
}

.actions-container {
  --margin-top: 120px;
  margin-top: var(--margin-top);
}

[data-theme="legrandtheatre"] .actions-container {
  --margin-top: 150px;
  transform: scaleX(0.6) scaleY(0.75);
}

.cancel-button {
  background: none;
  border: none;
  font-family: var(--header-font-family);
  text-transform: uppercase;
  color: white;
  font-size: 50px;
  text-decoration: underline;
}

[data-theme="legrandtheatre"] .cancel-button {
  font-family: var(--text-font-family);
  font-size: 60px;
  text-decoration: none;
  border-bottom: 5px solid white;
}
