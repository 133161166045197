.container {
  padding:
    0
    123px;
}

[data-theme="legrandtheatre"] .container {
  padding: 0;
}

[data-theme="legrandtheatre"] .container {
  padding: 0;
}

.header {
  --target-font-size: 80px;
  --target-line-height: 124px;

  font-family: var(--header-font-family);
  font-size: var(--target-font-size);
  line-height: var(--target-line-height);
  letter-spacing: 3.5px;
}

[data-theme="legrandtheatre"] .header {
  --target-font-size: 105px;
  --target-line-height: 100px;
  text-transform: uppercase;
  padding: 0;
}

.message {
  --target-margin-top: 64px;
  margin-top: var(--target-margin-top);
}

[data-theme="legrandtheatre"] .message {
  --target-margin-top: 55px;
  transform: scaleX(0.85) scaleY(0.95);
  text-transform: none;
  font-size: 45px;
}
