.container {
  padding: 207px;
}

[data-theme="legrandtheatre"] .container {
  padding:
    100px
    0;
}

.like-icon {
  height: 172px;
  width: 152px;
}

.text-container {
  font-family: var(--header-font-family);
  text-transform: uppercase;
}

.fr {
  font-size: 80px;
  letter-spacing: 3.5px;
}

[data-theme="legrandtheatre"] .fr {
  font-size: 210px;
}

.en {
  font-size: 60px;
  letter-spacing: 5.42px;
}

.separator {
  border: none;
  width: 100%;
  background-color: #FFFFFF;
  height: 12px;
  margin:
    60px
    auto;
}
