.container {
  --border-size: 20px;

  height: calc(var(--height) - calc(var(--border-size) * 2));
  width: calc(var(--width) - calc(var(--border-size) * 2));

  border:
    var(--border-size)
    solid
    var(--theme-accent-background-color);

  display: flex;
  flex-direction: column;
  justify-content: center;
}

[data-theme="legrandtheatre"] .container {
  --horizontal-padding: 96px;

  border: none;
  background: var(--theme-accent-background-color);
  padding: 0 var(--horizontal-padding);
  height: var(--height);
  width: calc(var(--width) - calc(2 * var(--horizontal-padding)));
}

.decoration-container {
  position: absolute;
  top: var(--border-size);
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
}

.decoration-box {
  background-color: var(--theme-accent-background-color);
  padding:
    228px
    44px
    58px
    44px;
}
