.container {
  height: var(--height);
  width: var(--width);
}

.picture {
  display: block;
  height: var(--height);
  width: var(--width);
  object-fit: cover;
}
