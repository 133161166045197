.container {
  padding: 134px;
}

[data-theme="legrandtheatre"] .container {
  padding: 0;
}

[data-theme="legrandtheatre"] .container {
  padding: 113px;
}

.header {
  font-family: var(--header-font-family);
  text-transform: uppercase;
  margin-bottom: 46px;
}

.fr .header {
  --target-font-size: 60px;
  --target-line-height: 86px;
  font-size: var(--target-font-size);
  line-height: var(--target-line-height);
  letter-spacing: 2.62px;
}

[data-theme="legrandtheatre"] .fr .header {
  --target-font-size: 110px;
  --target-line-height: 100px;
}

[data-theme="legrandtheatre"] .fr .text {
  transform: scaleX(0.85) scaleY(0.95);
  font-size: 46px;
  text-transform: none;
}

[data-theme="legrandtheatre"] .fr .header {
  --target-font-size: 110px;
  --target-line-height: 100px;
}

[data-theme="legrandtheatre"] .fr .text {
  transform: scaleX(0.8) scaleY(0.95);
  font-size: 46px;
  text-transform: uppercase;
}

.en {
  font-size: 36px;
  letter-spacing: 1.58px;
}

.en .header {
  font-size: 48px;
  line-height: 70px;
  letter-spacing: 2.1px;
}

.separator {
  border: none;
  width: 100%;
  height: 12px;
  background-color: #FFFFFF;
  margin:
    118px
    auto;
}
