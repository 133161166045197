.container {
  --bottom-disatance: 0px;
  --border-size: 8px;

  position: absolute;
  bottom: var(--bottom-disatance);
  left: 0;

  width: calc(
    var(--width) -
    calc(var(--border-size) * 2)
  );

  background-color: var(--theme-accent-background-color);
  border: var(--border-size) solid #000000;
}

[data-theme="legrandtheatre"] .container {
  width: 100%;
  border: none;
}

.scan-form,
.scan-form * {
  position: absolute;
  top: 0;
  left: 0;
  height: 0;
  width: 0;
  z-index: -10;
}
