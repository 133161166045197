.container {
  --target-horizontal-padding: 125px;

  padding:
    0
    var(--target-horizontal-padding);
}

[data-theme="legrandtheatre"] .container {
  --target-horizontal-padding: 0;
  text-transform: uppercase;
}

.header {
  --target-font-size: 80px;

  font-family: var(--header-font-family);
  font-size: var(--target-font-size);
  letter-spacing: 3.5px;
  text-transform: uppercase;
  
  margin-bottom: 70px;
}

[data-theme="legrandtheatre"] .header {
  --target-font-size: 165px;
  margin-top: 62px;
  margin-bottom: 22px;
}

[data-theme="legrandtheatre"] .header {
  --target-font-size: 165px;
  margin-top: 62px;
  margin-bottom: 22px;
}

.prize-name {
  --target-font-size: 60px;

  font-family: var(--header-font-family);
  font-size: var(--target-font-size);
  letter-spacing: 2.64px;
  text-transform: uppercase;

  margin:
    78px
    0
    56px
    0;
}

[data-theme="legrandtheatre"] .prize-name {
  --target-font-size: 115px;
}

.sending {
  margin-top: 60px;
}

[data-theme="legrandtheatre"] .congratulations-container,
[data-theme="legrandtheatre"] .details-container {
  transform: scaleX(0.8) scaleY(0.95);
}

[data-theme="legrandtheatre"] .congratulations,
[data-theme="legrandtheatre"] .details {
  font-size: 46px;
  text-transform: none;
}

[data-theme="legrandtheatre"] .congratulations-container,
[data-theme="legrandtheatre"] .details-container {
  transform: scaleX(0.8) scaleY(0.95);
}

[data-theme="legrandtheatre"] .congratulations,
[data-theme="legrandtheatre"] .details {
  font-size: 46px;
}
